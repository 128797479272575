import styled from 'styled-components';
import {textLarge, textMedium} from '../../../../styles/texts';
import Input from '../../../../components/UI/Input';
import Select from "../../../../components/UI/select";

const Title = styled.p`
  ${textLarge};
  margin-bottom: 5px;
`;

const SubTitle = styled.p`
  ${textMedium};
  margin-bottom: 10px;
`;

const Block = styled.div`
  margin-bottom: 60px;
`;

const InputBlock = styled.div`
  display: flex;
  align-items: stretch;
  gap: 0 15px;
  margin-bottom: 20px;

  @media (${({ theme }) => theme.base.breakpoint.M}) {
    gap: 15px;
    flex-direction: column;
  }
`;

const SettingInput = styled(Input)`
  max-width: 200px;
  width: 100%;
  margin-bottom: 0;

  @media (${({ theme }) => theme.base.breakpoint.M}) {
    max-width: 100%;
  }
`;

const SelectStyle = styled(Select)`
  max-width: 338px;
`;

export { Title, Block, InputBlock, SettingInput, SubTitle, SelectStyle };
