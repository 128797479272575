import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use-media';
import { CircularProgress } from '@mui/material';

import * as S from './styled';
import station from '../../../assets/img/stationImg.png';
import { BatteryAnimation, CopyIcon, ErrorIcon, PlayIcon, ReadyIcon, StopIcon } from '../../Icons';
import BatteryPreparingAnimated from '../../AnimatedIcons/battery-preparing-animated';
import { OfflineIcon } from '../../Icons/OfflineIcon';
import CommonModal from '../CommonModal';
import WarningModal from '../WarningModal';
import AvailableUsers from '../AvailableUsers';
import ActionsWithUser from '../ActionsWithUser';
import ListViewAdaptive from './ListViewAdaptive';
import { CHARGING_STATUS_TYPES } from '../../../enums';
import { useUserInfo } from '../../../context';
import {useQuery} from "@apollo/client";
import {GET_LATES_CHARGINSESSION} from "../../../apollo/chargingHistory";

const ListView = ({
  value,
  startChargingProcess,
  stopChargingProcess,
  loading,
  siteValue,
  handlerOpenEditModal,
  showErrorModal,
}) => {
  const { t } = useTranslation();
  const { isSiteAdmin, info } = useUserInfo();
  const isAdaptiveView = useMedia({
    maxWidth: 992
  });

  const [connectorValue, setConnectorValue] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [isOpenActionsModal, setIsOpenActionsModal] = useState(false);
  const { data: latest_chargingsession, loading: get_session_loading } = useQuery(GET_LATES_CHARGINSESSION, {
        variables: {
            filter: {
                evseId: value.evseId
            }
        },
      fetchPolicy: "no-cache"
  });

  const statusInfo = value?.connectorStates?.[0];
  const newestChargingSession = latest_chargingsession?.charging_latest_session_for_evse_id;

  const handlerChooseUser = async (user = null) => {
    closeModal();
    await startChargingProcess(value, user);
    setWaiting(true);
    setTimeout(() => setWaiting(false), 2500);
  };

  const handlerStartProcess = () => {
    if (isSiteAdmin) {
      setIsOpenModal(true);
    } else {
      handlerChooseUser();
    }
  };

  const handlerOpenActionsModal = () => {
    setIsOpenActionsModal(true);
    setConnectorValue(value);
  };

  const closeActionsModal = () => setIsOpenActionsModal(false);

  const closeModal = () => setIsOpenModal(false);

  const closeWarningModal = () => setIsOpenWarningModal(false);

  const handlerShowErrorModal = () => showErrorModal(statusInfo?.message);

  const handlerStopProcess = async () => {
    await stopChargingProcess(value);
    closeWarningModal();
    setWaiting(true);
    setTimeout(() => setWaiting(false), 2500);
  };

  const setStatusWaiting = useMemo(() => {
    return (
      <S.StatusBlock>
        <S.StatusInfo>
          <S.WaitingBlock>
            <CircularProgress />
          </S.WaitingBlock>
          <S.StatusInfoText>{t('overviewWallboxes.waiting')}</S.StatusInfoText>
        </S.StatusInfo>
      </S.StatusBlock>
    );
  }, [waiting, get_session_loading]);

  const checkStatus = useMemo(() => {
    switch (statusInfo?.state) {
      case CHARGING_STATUS_TYPES.READY: {
        return (
          <S.StatusBlock>
            <S.StatusInfo ready="true">
              <ReadyIcon />
              <S.StatusInfoText>{t('overviewWallboxes.status_ready')}</S.StatusInfoText>
            </S.StatusInfo>
            <S.ActionButton onClick={handlerStartProcess}>
              <PlayIcon />
              {t('overviewWallboxes.start')}
            </S.ActionButton>
          </S.StatusBlock>
        );
      }

      case CHARGING_STATUS_TYPES.SUSPENDED_ENV:
      case CHARGING_STATUS_TYPES.SUSPENDED: {
          if(newestChargingSession && newestChargingSession?.user_id !== info.id && !isSiteAdmin){
              return (<>
                      <S.StatusBlockOccupied>
                          <BatteryAnimation />
                          <S.StatusInfoText>{t('overviewWallboxes.occupied')}</S.StatusInfoText>
                      </S.StatusBlockOccupied>
                  </>
              );
          } else {
              return (
                  <S.StatusBlock>
                      <S.StatusInfo>
                          <BatteryPreparingAnimated />
                          <S.StatusInfoText>{t('overviewWallboxes.suspended')}</S.StatusInfoText>
                      </S.StatusInfo>
                      <S.ActionButton onClick={handlerStartProcess}>
                          <PlayIcon />
                          {t('overviewWallboxes.start')}
                      </S.ActionButton>
                  </S.StatusBlock>
              );
          }
      }

      case CHARGING_STATUS_TYPES.PREPARING: {
        return (
          <S.StatusBlock>
            <S.StatusInfo>
              <BatteryPreparingAnimated />
              <S.StatusInfoText>{t('overviewWallboxes.preparing')}</S.StatusInfoText>
            </S.StatusInfo>
              <S.ActionButton onClick={handlerStartProcess}>
                  <PlayIcon />
                  {t('overviewWallboxes.start')}
              </S.ActionButton>
          </S.StatusBlock>
        );
      }

      case CHARGING_STATUS_TYPES.CHARGING: {
          if(newestChargingSession && newestChargingSession?.user_id !== info.id && !isSiteAdmin){
              return (<>
                      <S.StatusBlockOccupied>
                          <BatteryAnimation />
                          <S.StatusInfoText>{t('overviewWallboxes.occupied')}</S.StatusInfoText>
                      </S.StatusBlockOccupied>
                  </>
              );
          } else {
              return (
                  <S.StatusBlock>
                      <S.StatusInfo>
                          <BatteryAnimation />
                          <S.StatusInfoText>{t('overviewWallboxes.charging')}</S.StatusInfoText>
                      </S.StatusInfo>
                      <S.ActionButton charging="true" onClick={() => setIsOpenWarningModal(true)}>
                          <StopIcon />
                          {t('overviewWallboxes.stop')}
                      </S.ActionButton>
                  </S.StatusBlock>
              );
          }

      }

      case CHARGING_STATUS_TYPES.OFFLINE: {
        return (
          <S.StatusBlock>
            <S.StatusInfo offline="true">
              <OfflineIcon />
              <S.StatusInfoText>{t('overviewWallboxes.offline')}</S.StatusInfoText>
            </S.StatusInfo>
          </S.StatusBlock>
        );
      }

      case CHARGING_STATUS_TYPES.ERROR: {
        return (
          <S.StatusBlock>
            <S.StatusInfo>
              <ErrorIcon />
              <S.StatusInfoText>{t('overviewWallboxes.error')}</S.StatusInfoText>
            </S.StatusInfo>
            <S.ActionButton charging="true" onClick={handlerShowErrorModal}>
              {t('overviewWallboxes.view')}
            </S.ActionButton>
          </S.StatusBlock>
        );
      }

      case CHARGING_STATUS_TYPES.NO_CONNECTION:
      case CHARGING_STATUS_TYPES.UNAVAILABLE: {
        return (
          <S.StatusBlock>
            <S.StatusInfo offline="true">
              <OfflineIcon />
              <S.StatusInfoText>{t('overviewWallboxes.no_connection')}</S.StatusInfoText>
            </S.StatusInfo>
          </S.StatusBlock>
        );
      }

      default:
        return (
          <S.StatusBlock>
            <S.StatusInfo>
              <ErrorIcon />
              <S.StatusInfoText>{t('overviewWallboxes.error')}</S.StatusInfoText>
            </S.StatusInfo>
            <S.ActionButton charging="true" onClick={handlerShowErrorModal}>
              {t('overviewWallboxes.view')}
            </S.ActionButton>
          </S.StatusBlock>
        );
    }
  }, [statusInfo?.state, get_session_loading]);

  if (isAdaptiveView) {
    return <ListViewAdaptive value={value} siteValue={siteValue} latestChargingSession={latest_chargingsession} get_session_loading={get_session_loading} />;
  }

  return (
    <S.Wrapper>
      <CommonModal open={isOpenModal} onClose={closeModal}>
        <AvailableUsers
          onClose={closeModal}
          handlerChooseUser={handlerChooseUser}
          users={value?.contracts}
          evseId={value?.evseId}
        />
      </CommonModal>

      <CommonModal open={isOpenWarningModal} onClose={closeWarningModal}>
        <WarningModal
          onClose={closeWarningModal}
          handlerProcessedClick={handlerStopProcess}
          description={''}
        />
      </CommonModal>

      <CommonModal open={isOpenActionsModal} onClose={closeActionsModal}>
        <ActionsWithUser
          handlerCloseModal={closeActionsModal}
          connectorValue={connectorValue}
          activeUsers={value?.contracts}
          activeGroups={value?.groups}
          siteValue={siteValue}
        />
      </CommonModal>

      <S.ListImageBlock>
        <S.ListImage src={station} alt="station" />
      </S.ListImageBlock>

      {waiting || loading || get_session_loading ? setStatusWaiting : checkStatus}

      <S.TextBlock>
        <S.TitleBlock>
          <S.Title>{value?.name}</S.Title>
          {isSiteAdmin && (
            <CopyIcon width={14} height={14} onClick={() => handlerOpenEditModal(value)} />
          )}
        </S.TitleBlock>

        <S.SubTitle>{value?.evseId}</S.SubTitle>
      </S.TextBlock>

      {isSiteAdmin && (
              <S.UserBlock>
                      {value?.groups?.length
                          ? value?.groups?.map((item) => (
                              <S.GroupItem key={item?.group_id}>{item?.group_name}</S.GroupItem>
                          ))
                          : null}
                      {value?.contracts?.length
                          ? value?.contracts?.map((item) => (
                              <S.UserItem key={item?.evcoId}>{item?.name}</S.UserItem>
                          ))
                          : null}
                  <S.UserCopyIcon>
                      <CopyIcon width={14} height={14} onClick={handlerOpenActionsModal} />
                  </S.UserCopyIcon>
              </S.UserBlock>
      )}
    </S.Wrapper>
  );
};

export default ListView;
