import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import addUser from '../../../assets/img/Icon - Add User.png';
import { CloseIcon } from '../../Icons';

const CommonModalWithHeader = ({
  title,
  onClose,
  subtitle,
  buttonText,
  buttonFunc,
  assigned = false,
  createGroupFunc = null,
  createGroupButtonText = "",
  children
}) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderText>{title}</S.HeaderText>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      </S.Header>

      <S.Content>
        {subtitle && <S.ExplainText>{subtitle}</S.ExplainText>}
        {children}

        <S.ButtonBlock assigned={assigned ? 'true' : 'false'}>
          <S.ActionButton
            text={
              <>
                {assigned ? <S.ActionButtonImage src={addUser} /> : null}
                {buttonText}
              </>
            }
            onClick={buttonFunc}
            assigned={assigned ? 'true' : 'false'}
          />
            {createGroupFunc && <S.ActionButton
                text={
                    <>
                        {assigned ? <S.ActionButtonImage src={addUser} /> : null}
                        {createGroupButtonText}
                    </>
                }
                onClick={createGroupFunc}
                assigned={createGroupFunc ? 'true' : 'false'}
            /> }
            {assigned && <S.CancelButton text={t('modal.close')} onClick={onClose} />}
        </S.ButtonBlock>
      </S.Content>
    </S.Wrapper>
  );
};

export default CommonModalWithHeader;
