import React, { useState } from 'react';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { CopyIcon } from '../../Icons';
import * as S from './styled';
import CardItem from '../CardItem';
import CommonButton from '../button/CommonButton';
import CommonModal from '../CommonModal';
import ActionsBlock from '../ActionsBlock';
import ListView from '../ListView';
import AlertMessage from '../AlertMessage';
import { api } from '../../../services';
import EditTitleModal from '../EditTitleModal';
import { TAB_TYPES } from '../../../enums';
import ErrorModal from '../ErrorModal';
import { GET_STATUS_FOR_CONNECTOR } from '../../../apollo/chargingHistory';
import { useUserInfo } from '../../../context';
import { GET_CONTRACT_FOR_USER, GET_USERS_CONTRACT } from '../../../apollo/contracts';
import { CircularProgress } from '@mui/material';
import {START_CHARGING, STOP_CHARGING} from "../../../apollo/connector";

const HistoryCard = ({ value, activeTab, siteValue, siteId}) => {
  const { t } = useTranslation();
  const { isSiteAdmin, info } = useUserInfo();

  const { data, loading: userLoading } = useQuery(GET_CONTRACT_FOR_USER, {
    skip: isSiteAdmin,
    variables: {
      contractFilter: {
        siteId: siteId
      }
    }
  });

  const [updateConnectorStatus, { loading }] = useLazyQuery(GET_STATUS_FOR_CONNECTOR, {
    skip: siteId === '',
    variables: {
      chargepointFilter: {
        siteId
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const [startCharging, { data: start_response, loading: start_loading, error: start_error }] = useMutation(START_CHARGING);
  const [stopCharging, { data: stop_response, loading: stop_loading, error: stop_error }] = useMutation(STOP_CHARGING);
  //const [resetChargepoint, { data: reset_response, loading: reset_loading, error: reset_error }] = useMutation(RESET_CHARGEPOINT);

  const [isOpenActionModal, setIsOpenActionModal] = useState(false);
  const [waitingLoader, setWaitingLoader] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorValue, setErrorValue] = useState(null);
  const [errorText, setErrorText] = useState('');
  const [editValue, setEditValue] = useState('');
  const [isChargepoint, setIsChargepoint] = useState(false);

  const handlerOpenActionModal = () => {
    setIsOpenActionModal(true);
  };

  const handlerCloseActionModal = () => {
    setIsOpenActionModal(false);
  };

  const handlerCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const startChargingProcess = async (value, user) => {
    //const params = { evse_id: value?.evseId, evco_id: user?.evcoId };

    const variables = {
      contractConnector: {
        evcoId: user?.evcoId,
        evseId: value?.evseId
      }
    }

    if (!isSiteAdmin) {
      //params.evco_id = data?.users?.[0]?.contracts?.[0]?.evcoId;
      variables.contractConnector.evcoId = data?.contracts?.[0]?.evcoId;
    }
    try {
      await startCharging({variables})
      if (start_error != null){
        setOpenSnackbar(true);
        const errorMessage = start_error?.message;
        setErrorText(errorMessage);
      } else {
        setTimeout(() => {
          updateConnectorStatus();
        }, 5000);
      }
    } catch (e) {
      setOpenSnackbar(true);
      const errorMessage =
          e?.response?.data?.detail?.[0] || e?.response?.data?.status
              ? `[ERROR]: ${e?.response?.data?.detail?.[0] || e?.response?.data?.status}`
              : e?.message;
      setErrorText(errorMessage);
    }

    /*try {
      await api.charging.startCharging(params);
      setTimeout(() => {
        updateConnectorStatus();
      }, 2000);
    } catch (e) {
      setOpenSnackbar(true);
      const errorMessage =
        e?.response?.data?.detail?.[0] || e?.response?.data?.status
          ? `[ERROR]: ${e?.response?.data?.detail?.[0] || e?.response?.data?.status}`
          : e?.message;
      setErrorText(errorMessage);
    }*/

  };

  const stopChargingProcess = async (value) => {
    const params = { evse_id: value?.evseId, transaction_id: 0 };

    const variables = {
      connectorTransaction: {
        evseId: value?.evseId,
        transactionId: 0
      }
    }

    try {
      //await api.charging.stopCharging(params);
      await stopCharging({variables})
      if (stop_error != null){
        setOpenSnackbar(true);
        const errorMessage = start_error?.message;
        setErrorText(errorMessage);
      } else {
        setTimeout(() => {
          updateConnectorStatus();
        }, 5000);
      }
    } catch (e) {
      setOpenSnackbar(true);
      const errorMessage =
        e?.response?.data?.detail?.[0] || e?.response?.data?.status
          ? `[ERROR]: ${e?.response?.data?.detail?.[0] || e?.response?.data?.status}`
          : e?.message;
      setErrorText(errorMessage);
    }
  };

  const handlerOpenEditModal = (value, chargepoint = false) => {
    setIsOpenEditModal(true);
    setEditValue(value);
    setIsChargepoint(chargepoint);
  };

  const showErrorModal = (value) => {
    setOpenErrorModal(true);
    setErrorValue(`${JSON.parse(value)?.error_code}: ${JSON.parse(value)?.info}`);
  };

  const closeErrorModal = () => {
    setOpenErrorModal(false);
    setErrorValue(false);
  };

  const showErrorWithReset = (text) => {
    setOpenSnackbar(true);
    setErrorText(text);
  };

  const handlerActionWithChargepoint = (data) => {
    setWaitingLoader(data);
  };

  const closeSnackbar = () => setOpenSnackbar(false);

  return (
    <S.Container
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.3 }}>
      {waitingLoader || userLoading ? (
        <S.WaitingBlock>
          <CircularProgress />
        </S.WaitingBlock>
      ) : (
        <>
          <AlertMessage open={openSnackbar} onClose={closeSnackbar} errorText={errorText} />
          <ErrorModal open={openErrorModal} onClose={closeErrorModal} title={errorValue} />
          <CommonModal open={isOpenActionModal} onClose={handlerCloseActionModal}>
            <ActionsBlock
              title={`Wallbox / Cluster: ${value?.name}`}
              handlerActionWithChargepoint={handlerActionWithChargepoint}
              siteId={siteId}
              showErrorWithReset={showErrorWithReset}
              onClose={handlerCloseActionModal}
              chargepointID={value?.id}
            />
          </CommonModal>
          <CommonModal open={isOpenEditModal} onClose={handlerCloseEditModal}>
            <EditTitleModal
              onClose={handlerCloseEditModal}
              value={editValue}
              chargepoint={isChargepoint}
            />
          </CommonModal>
          <S.Header>
            <div>
              <S.HeaderTitle>
                {t('chargingHistory.card_header_title')}: {value?.name || ''}{' '}
                {isSiteAdmin && <CopyIcon onClick={() => handlerOpenEditModal(value, true)} />}
              </S.HeaderTitle>
              <S.HeaderSubtitle>
                <span>
                  {value?.vendor}: {value?.model}
                </span>
                <span>{value?.serialNumber}</span>
              </S.HeaderSubtitle>
            </div>
            <CommonButton text={'Actions'} onClick={handlerOpenActionModal} />
          </S.Header>
          <S.Content activeTab={activeTab === TAB_TYPES.CARD}>
            {/*{*/}
            {/*  value?.connectors?.length*/}
            {/*  ? value?.connectors?.map(item => (*/}
            {/*    <CarItem key={item.id} type={item?.state || 'ready'} value={item} />*/}
            {/*  ))*/}
            {/*  : <p>Empty data..</p>*/}
            {/*}*/}
            {value?.connectors?.length ? (
              value?.connectors?.map((item) =>
                activeTab === TAB_TYPES.LIST ? (
                  <ListView
                    key={item.evseId}
                    value={item}
                    startChargingProcess={startChargingProcess}
                    loading={loading}
                    stopChargingProcess={stopChargingProcess}
                    handlerOpenEditModal={handlerOpenEditModal}
                    showErrorModal={showErrorModal}
                    siteValue={siteValue}
                  />
                ) : (
                  <CardItem
                    key={item.evseId}
                    value={item}
                    loading={loading}
                    startChargingProcess={startChargingProcess}
                    stopChargingProcess={stopChargingProcess}
                    handlerOpenEditModal={handlerOpenEditModal}
                    showErrorModal={showErrorModal}
                    siteValue={siteValue}
                  />
                )
              )
            ) : (
              <p>No connectors..</p>
            )}
            {/*<CardItem />*/}
          </S.Content>
        </>
      )}
    </S.Container>
  );
};

export default HistoryCard;
