import styled from 'styled-components';

import { FormControlLabel, Checkbox } from '@mui/material';

const FormControlWrapper = styled(FormControlLabel)`
  width: 100%;

  span:first-child {
    padding: 0 22px 0 0;
  }

  span:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const FormControlWrapperBold = styled(FormControlLabel)`
  width: 100%;

  span:first-child {
    padding: 0 22px 0 0;
  }

  span:last-child {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  }
`;


const CustomCheckbox = styled(Checkbox)``;

const CustomCheckboxDisabled = styled(Checkbox)`
  filter: greyscale(100%)
`;

export { FormControlWrapper, CustomCheckbox, FormControlWrapperBold, CustomCheckboxDisabled };
