import { gql } from '@apollo/client';

export const ALL_CHARGEPOINT = gql`
  query AllChargepoints {
    chargepoints {
      chargeBoxSerialNumber
      id
      name
      vendor
      serialNumber
      firmwareVersion
      iccid
      imsi
      meterSerialNumber
      meterType
      model
      connectors {
        evseId
        name
        contracts {
          evcoId
          name
        }
      }
    }
  }
`;

export const ALL_CHARGING_SESSIONS = gql`
  query getAllChargingSessions($chargingSessionFilter: ChargingSessionFilter) {
    charging_sessions(chargingSessionFilter: $chargingSessionFilter) {
      transactionId
      evse_id
      connector_name
      site_id
      contract_name
      user_name
      user_id
      amount
      current_cost
      connector {
        name
        evseId
        chargepoint {
          name
          serialNumber
        }
      }
      cost
      startTime
      charging_time
      contract {
        name
        evcoId
      }
    }
  }
`;

export const GET_CHARGING_SESSIONS_BY_SITE = gql`
  query getChargingSessionsBySite($siteFilter: SiteFilter!) {
    sites(siteFilter: $siteFilter) {
      chargepoints {
        id
        model
        vendor
        name
        serialNumber
        connectors {
          evseId
          id
          name
          connectorStates {
            message
            state
            id
          }
          contracts {
            name
            evcoId
          }
          groups {
            group_id
            group_name
            billing_method
          }
        }
      }
      id
      name
      tenant
    }
  }
`;

export const GET_STATUS_FOR_CONNECTOR = gql`
  query getStatusForConnector($chargepointFilter: ChargepointFilter) {
    chargepoints(chargepointFilter: $chargepointFilter) {
      connectors {
        id
        evseId
        name
        connectorStates {
          message
          state  
          id
        }
      }
    }
  }
`;

export const UPDATE_CONNECTOR = gql`
  mutation updateConnector($connector: UpdateConnectorInput!, $filter: ConnectorFilter!) {
    updateConnectors(connector: $connector, filter: $filter)
  }
`;

export const UPDATE_CHARGEPOINT = gql`
  mutation updateChargepoints($chargepoint: UpdateChargepointInput!, $filter: ChargepointFilter!) {
    updateChargepoints(chargepoint: $chargepoint, filter: $filter)
  }
`;

export const GET_CONTRACT = gql`
  query getContract($filter: ConnectorFilter!) {
    connector(filter: $filter) {
      evseId
      id
      name
      connectorStates {
        state
        message
        id
      }
      chargingSessions {
        user_id
        user_name
        user_email
        transactionId
        state
      }
      contracts {
        evcoId
        name
        tenant
        user {
          fullName
          email
          id
        }
        site {
          name
          id
        }
      }
      groups {
        group_id
        group_name
        billing_method
      }
    }
  }
`;

export const GET_CHARGING_SESSION = gql`
  query getChargingSession($filter: ChargingSessionFilter!) {
    charging_session(filter: $filter) {
      startTime
      stopTime
      amount
      cost
      charging_time
      connector {
        connectorStates {
          id
          message
          state
        }
      }
      contract {
        site {
          id
        }
      }
    }
  }
`;

export const GET_CHARGING_SESSION_FOR_CHART = gql`
  query getChargingSessionForChart($chargingSessionFilter: ChargingSessionFilter) {
    charging_sessions(chargingSessionFilter: $chargingSessionFilter) {
      amount
      cost
      startTime
    }
  }
`;

export const GET_CHARGEPOINT_BY_ID = gql`
  query getConnectorByID($filter: ChargepointFilter!) {
    chargepoint(filter: $filter) {
      name
      id
      model
      vendor
    }
  }
`;

export const GET_LATES_CHARGINSESSION = gql`
query get_latest_charging_sessions($filter: ChargingSessionFilter!) {
  charging_latest_session_for_evse_id(filter: $filter) {
    user_id
  }
}
`
