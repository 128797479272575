import styled from 'styled-components';
import { textLarge, textMedium, textSmall, titleText } from '../../../styles/texts';
import CommonButton from '../button/CommonButton';

const CardWrapper = styled.div`
  max-width: 404px;
  width: 100%;
  background: ${({ theme }) => theme.color.background.secondary};
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  border-radius: 14px;
`;

const ImageBlock = styled.div`
  position: relative;
  margin-bottom: 60px;
  height: 201px;
  border-radius: 14px;

  background: linear-gradient(89.94deg, #325362 0.05%, rgba(51, 51, 51, 0.6) 120.1%),
    url(${({ image }) => `${image}`}) center no-repeat;
  z-index: 1;

  ${({ error }) =>
    error &&
    `
    &:before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 14px;
      background: rgba(51, 51, 51, 0.6);
      z-index: 2;
    }
  `}
`;

const OfflineLastDateText = styled.p`
  width: 400px;
  margin-top: 6px;
  ${textMedium};
  white-space: normal;
  text-align: center;
  line-height: 140%;
  font-weight: 500;
  color: ${({ theme }) => theme.color.background.secondary};
`;

const WaitingBlock = styled.div`
  margin-right: 16px;
`;

const StationImg = styled.img``;

const StatusBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const StatusBlockOccupied = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  filter: grayscale(100%);
`;

const StatusText = styled.p`
  margin-top: 6px;
  ${titleText};
  color: ${({ theme }) => theme.color.background.secondary};
`;

const StatusTextOccupied = styled.p`
  margin-top: 6px;
  ${titleText};
  color: ${({ theme }) => theme.color.background.secondary};
  filter: grayscale(100%);
`;

const ActionBlock = styled.div`
  max-width: 80px;
  max-height: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: -80px;
  transform: translate(-50%, -50%);
  z-index: 10;
  border: 4px solid
    ${({ theme, charging }) => {
      if (charging) {
        return theme.color.background.red;
      }

      return theme.color.background.green;
    }};
  background: ${({ theme }) => theme.color.background.secondary};
  box-shadow: 0 2px 10px rgba(83, 175, 49, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s all;

  &:hover {
    background: ${({ theme, charging }) => {
      if (charging) {
        return theme.color.background.red;
      }

      return theme.color.background.green;
    }};

    svg > * {
      fill: ${({ theme }) => theme.color.background.secondary};
    }
  }
`;

const InfoBlock = styled.div`
  padding: 0 20px 48px;

  svg {
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.4;
    }
  }
`;

const InfoHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const InfoHeaderTitle = styled.p`
  margin-bottom: 4px;
  ${textMedium};
  color: ${({ theme }) => theme.color.text.primary.strongDarkBlue};
  font-weight: 500;
`;

const InfoHeaderSubtitle = styled.p`
  ${textSmall};
  color: ${({ theme }) => theme.color.text.primary.darkGray};
  font-weight: 500;
  letter-spacing: -0.1em;
`;

const Content = styled.div`
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const ContentItem = styled.div`
  width: 49%;

  &:last-child {
    text-align: right;
  }
`;

const ContentBorder = styled.div`
  width: 1px;
  height: 40px;
  background: ${({ theme }) => theme.color.text.primary.silverGray};
`;

const ContentItemTitle = styled.p`
  margin-bottom: 6px;
  ${textMedium};
  color: ${({ theme }) => theme.color.text.secondary.lightBlue};
  font-weight: 500;
`;

const ContentItemData = styled.p`
  ${textLarge};
  color: ${({ theme }) => theme.color.text.primary.dark};
`;

const ManageButton = styled(CommonButton)`
  margin: 0 auto;
  display: block;
  background: ${({ theme }) => theme.color.main.primary};
`;

const UserBlockTitle = styled.p``;

const UserBlock = styled.div`
  width: 100%;
  margin-top: 6px;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 94px;
  overflow: auto;
  padding: 10px 14px;
  border: 1px solid ${({ theme }) => theme.color.background.outline};
  border-radius: 8px;
`;

const GroupItem = styled.div`
  padding: 8px 18px;
  margin-right: 14px;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: 8px;
  margin-bottom: 6px;
  font-weight: bold;
`;

const UserItem = styled.div`
  padding: 8px 18px;
  margin-right: 14px;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: 8px;
  margin-bottom: 6px;
`;

const UserCopyIcon = styled.div`
  position: absolute;
  right: 14px;
  top: 14px;

  svg {
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export {
  CardWrapper,
  ImageBlock,
  OfflineLastDateText,
  StationImg,
  StatusBlock,
  StatusText,
  ActionBlock,
  InfoBlock,
  InfoHeader,
  UserBlockTitle,
  InfoHeaderTitle,
  InfoHeaderSubtitle,
  Content,
  ContentItem,
  ContentBorder,
  WaitingBlock,
  ContentItemTitle,
  ContentItemData,
  ManageButton,
  UserBlock,
  UserItem,
  UserCopyIcon,
  StatusBlockOccupied,
  StatusTextOccupied,
  GroupItem
};
