import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import * as S from './styled';
import ContentHeader from '../../components/UI/ContentHeader';
import { PasswordSettings, UserSettings } from './Tabs';
import AlertMessage from '../../components/UI/AlertMessage';
import { ALERT_TYPES } from '../../enums';
import PaymentSettings from "./Tabs/PaymentSettings";
import {useParams} from "react-router-dom";

const UserSettingsPage = () => {

  const { t } = useTranslation();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [alertType, setAlertType] = useState(ALERT_TYPES.ERROR);
  const {tab} = useParams();
  const [tabIndex, setTabIndex] = useState(isNaN(parseInt(tab)) ? 0 :  parseInt(tab) );

  const handleTabChange = (e, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
    setAlertText('');
  };

  const handlerActions = (text, type = ALERT_TYPES.SUCCESS) => {
    setOpenSnackbar(true);
    setAlertText(text);
    setAlertType(type);
  };

  return (
    <S.Wrapper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.3 }}>
      <AlertMessage
        open={openSnackbar}
        onClose={closeSnackbar}
        errorText={alertText}
        severity={alertType}
      />
      <ContentHeader showTab={false} />
      <S.Title>{t('settings.user_settings')}</S.Title>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label={t('settings.user_info')} />
        <Tab label={t('settings.password_settings')} />
          <Tab label="PaymentSetting" />
      </Tabs>
      <S.Content>
        {tabIndex === 0 && <UserSettings handlerActions={handlerActions} />}
        {tabIndex === 1 && <PasswordSettings handlerActions={handlerActions} />}
          {tabIndex === 2 && <PaymentSettings handlerActions={handlerActions} />}
      </S.Content>
    </S.Wrapper>
  );
};

export default UserSettingsPage;
