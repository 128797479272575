import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  max-height: 500px;
  max-width: 800px;
  overflow-y: scroll;
`;

const CheckboxItem = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 12px;
  margin-right: 0;
  border-radius: 8px;
  transition: 0.3s all;
  z-index: 1;
  font-weight: bold;
  &:hover {
    background: ${({ theme }) => theme.color.background.primary};
  }
  
  *${({ theme, active }) =>
      active &&
      `
    background: ${theme.color.background.primary};  
  `};
  
  span {
    display: flex;
    align-items: center;
  }

  svg {
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s all;
    z-index: 10;

    &:hover {
      transform: scale(1.1);
    }
  }

`;

const CheckboxSubItem = styled.div`
  width: 85%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 12px;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 8px;
  transition: 0.3s all;
  z-index: 1;
  float: right;

  ${({ theme, active }) =>
    active &&
    `
    background: ${theme.color.background.dark};
  `};

  &:hover {
    background: ${({ theme }) => theme.color.background.primary};
  }

  span {
    display: flex;
    align-items: stretch;
  }

  svg {
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s all;
    z-index: 10;

    &:hover {
      transform: scale(1.1);
    }
  }
  //filter: greyscale(100%);
  //color: #193d5b;
`;

const CheckboxSubItemDisabled = styled.div`
  width: 85%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 12px;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 8px;
  transition: 0.3s all;
  z-index: 1;
  float: right;

  ${({ theme, active }) =>
    active &&
    `
    background: ${theme.color.background.dark};
  `};

  &:hover {
    background: ${({ theme }) => theme.color.background.primary};
  }

  span {
    display: flex;
    align-items: stretch;
  }

  svg {
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s all;
    z-index: 10;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export { CheckboxWrapper, CheckboxItem, CheckboxSubItem, CheckboxSubItemDisabled };
