import React, {useState, useEffect} from 'react';
import {CircularProgress} from '@mui/material';
import {useTranslation} from 'react-i18next';

import * as S from './styled';
import CommonModalWithHeader from '../../../CommonModalWithHeader';
import Checkbox, {CheckboxBold} from '../../../Checkbox';
import {CopyIcon, RemoveIcon} from '../../../../Icons';
import {useMutation, useQuery} from '@apollo/client';
import {
    ASSIGN_CONTRACT_TO_CONNECTOR,
    DELETE_CONTRACT,
    GET_CONTRACT_BY_ID,
    REMOVE_CONTRACT_FROM_CONNECTOR
} from '../../../../../apollo/contracts';
import WarningModal from '../../../WarningModal';
import CommonModal from '../../../CommonModal';
import {GET_CONNECTOR_BY_ID} from '../../../../../apollo/connector';
import {ALERT_TYPES, USER_ACTION_TYPES} from '../../../../../enums';
import {
    ASSIGN_GROUP_TO_CONNECTOR, CREATE_GROUP,
    DELETE_GROUP,
    GET_GROUP_BY_ID,
    REMOVE_GROUP_FROM_CONNECTOR
} from "../../../../../apollo/group";
import {GET_CHARGING_SESSIONS_BY_SITE} from "../../../../../apollo/chargingHistory";

const AssignedUsers = ({
                           handlerChangeModal,
                           closeModal,
                           handlerActions,
                           activeUsers,
                           siteValue,
                           connectorValue,
                           handlerRefetchContract,
                           cardPage = false,
                           activeGroups
                       }) => {
    const {t} = useTranslation();
    /*const {data, loading} = useQuery(GET_CONTRACT_BY_ID, {
        variables: {
            contractFilter: {
                siteId: +siteValue?.id
            }
        }
    });*/

    const {data: groupData, loading: groupLoading } = useQuery(GET_GROUP_BY_ID, {
        variables: {
            groupFilter: {
                site_id: +siteValue?.id
            }
        }
    });

    const [assignContractToConnector] = useMutation(ASSIGN_CONTRACT_TO_CONNECTOR, {
        refetchQueries: [
            {
                query: GET_CONNECTOR_BY_ID,
                variables: {
                    filter: {
                        evseId: connectorValue?.evseId
                    }
                }
            },
            {
                query: GET_CHARGING_SESSIONS_BY_SITE,
                variables: {
                    siteFilter: {
                        id: siteValue?.id
                    }
                }
            },
        ]
    });

    const [assignGroupToConnector] = useMutation(ASSIGN_GROUP_TO_CONNECTOR,{
        refetchQueries: [
            {
                query: GET_CONNECTOR_BY_ID,
                variables: {
                    filter: {
                        evseId: connectorValue?.evseId
                    }
                }
            },
            {
                query: GET_CHARGING_SESSIONS_BY_SITE,
                variables: {
                    siteFilter: {
                        id: siteValue?.id
                    }
                }
            },
        ]
    })


    const [removeGroupFromConnector] = useMutation(REMOVE_GROUP_FROM_CONNECTOR,{
        refetchQueries: [
            {
                query: GET_CONNECTOR_BY_ID,
                variables: {
                    filter: {
                        evseId: connectorValue?.evseId
                    }
                }
            },
            {
                query: GET_CHARGING_SESSIONS_BY_SITE,
                variables: {
                    siteFilter: {
                        id: siteValue?.id
                    }
                }
            },
        ]
    })



    const [removeContractFromConnector] = useMutation(REMOVE_CONTRACT_FROM_CONNECTOR, {
        refetchQueries: [
            {
                query: GET_CONNECTOR_BY_ID,
                variables: {
                    filter: {
                        evseId: connectorValue?.evseId
                    }
                }
            },
            {
                query: GET_CHARGING_SESSIONS_BY_SITE,
                variables: {
                    siteFilter: {
                        id: siteValue?.id
                    }
                }
            },
        ]
    });

    const [deleteContract] = useMutation(DELETE_CONTRACT, {
        refetchQueries: [
            {
                query: GET_CONNECTOR_BY_ID,
                variables: {
                    filter: {
                        evseId: connectorValue?.evseId
                    }
                }
            },
            {
                query: GET_GROUP_BY_ID,
                variables: {
                    groupFilter: {
                        site_id: +siteValue?.id
                    }
                }
            },
            {
                query: GET_CHARGING_SESSIONS_BY_SITE,
                variables: {
                    siteFilter: {
                        id: siteValue?.id
                    }
                }
            },
        ]
    });

    const [deleteGroup] = useMutation(DELETE_GROUP,{
        refetchQueries: [
            {
                query: GET_CONNECTOR_BY_ID,
                variables: {
                    filter: {
                        evseId: connectorValue?.evseId
                    }
                }
            },
            {
                query: GET_GROUP_BY_ID,
                variables: {
                    groupFilter: {
                        site_id: +siteValue?.id
                    }
                }
            },
            {
                query: GET_CHARGING_SESSIONS_BY_SITE,
                variables: {
                    siteFilter: {
                        id: siteValue?.id
                    }
                }
            },
        ]
    })



    //const [checkboxData, setCheckboxData] = useState([]);
    const [checkboxGroupData, setCheckboxGroupData] = useState([]);
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const [isOpenGroupWarningModal, setIsOpenGroupWarningModal] = useState(false);
    const [deletedGroup, setDeletedGroup] = useState(null);
    const [deletedUser, setDeletedUser] = useState(null);

    const closeWarningModal = () => setIsOpenWarningModal(false);
    const closeGroupWarningModal = () => setIsOpenGroupWarningModal(false);

    const handlerOpenWarningModal = (user) => {
        setIsOpenWarningModal(true);
        setDeletedUser(user);
    };

    const handlerOpenGroupWarningModal = (group) => {
        setIsOpenGroupWarningModal(true);
        setDeletedGroup(group);
    };

    /*useEffect(() => {
        const checkedData = data?.contracts?.map((item) => {
            const isChecked = activeUsers?.find((user) => user.evcoId === item.evcoId);
            return {...item, checked: !!isChecked};
        });
        setCheckboxData(checkedData);
    }, [data?.contracts]);*/

    useEffect(() => {
        //console.log("useffekt Hook")
        //console.log("groupData?.groups")
        //console.log(groupData?.groups)
        //console.log("activeGroups")
        //console.log(activeGroups)
        //console.log("activeUsers")
        //console.log(activeUsers)
        const checkedGroupData = groupData?.groups?.map((item) => {
            const isChecked = activeGroups?.find((group) => group.group_id === item.group_id);

            let newItem = {...item};

            const newContracts = item?.contracts?.map((group_member) => {
                const group_member_checked = activeUsers?.find((user) => user.evcoId === group_member.evcoId);
                return {...group_member, checked: !!group_member_checked};
            })

            //console.log(newContracts)
            newItem.contracts = newContracts;

            return {...newItem, checked: !!isChecked};
        })
        //console.log(checkedGroupData)
        setCheckboxGroupData(checkedGroupData);
    }, [groupData?.groups])

    const handlerGroupChecked = (value) => {
        const variables = {
            assignFilter: {
                evse_id: connectorValue?.evseId,
                group_id: parseInt(value)
            }
        }

        checkboxGroupData?.map((item) => {

            if (item.group_id.toString() === value && item.checked) {
                removeGroupFromConnector({variables});

                item.checked = false;
            } else if (item.group_id.toString() === value && !item.checked) {
                assignGroupToConnector({variables});

                item.checked = true;
            }

        });
        //setCheckboxGroupData(newdata)
    }

    const handlerChecked = (value) => {
        const variables = {
            contractConnector: {
                evcoId: value,
                evseId: connectorValue?.evseId
            }
        };

        //const isChecked = checkboxGroupData.find(objekt => objekt.contracts.some(vertrag => vertrag.evcoId === value));
        //const isChecked = checkboxData?.find((item) => item.evcoId === value);

        checkboxGroupData?.map((group) => {
            group?.contracts?.map((item) => {
                if (item.evcoId === value && item.checked) {
                    removeContractFromConnector({variables});
                    if (cardPage) {
                        handlerRefetchContract();
                    }
                    item.checked = false;
                } else if (item.evcoId === value && !item.checked) {
                    assignContractToConnector({variables});
                    if (cardPage) {
                        handlerRefetchContract();
                    }
                    item.checked = true;
                }
            })

        });


    };

    const deleteUser = async () => {
        try {
            const params = {
                variables: {
                    filter: {
                        evcoId: deletedUser?.evcoId
                    }
                }
            };

            await deleteContract(params);
            handlerActions(t('modal.user_delete_success'));
        } catch (e) {
            handlerActions(e?.response?.data?.message, ALERT_TYPES.ERROR);
        } finally {
            closeWarningModal();
        }
    };

    const deleteUserGroup = async () => {
        try {
            const params = {
                variables: {
                    group: {
                        group_id: deletedGroup.group_id
                    }
                }
            };

            await deleteGroup(params);
            handlerActions(t('modal.group_delete_success'));
        } catch (e) {
            handlerActions(e?.response?.data?.message, ALERT_TYPES.ERROR);
        } finally {
            closeGroupWarningModal();
        }
    }

    /*const handleCreateGroup = async () => {
        const result = await createGroup({
            variables: {
                group: {
                    group_name: "SPECIAL TEST 2",
                    site_id: siteValue?.id
                }
            }
        })
    }*/

    if (groupLoading) {
        return <CircularProgress/>;
    }

    return (
        <CommonModalWithHeader
            title={t('modal.assigned_user')}
            subtitle={t('modal.available_user_explain')}
            buttonText={t('modal.add_new_user')}
            assigned={true}
            onClose={closeModal}
            createGroupFunc={() => handlerChangeModal(USER_ACTION_TYPES.CREATE_GROUP) }
            createGroupButtonText={t('modal.add_new_group')}
            buttonFunc={() => handlerChangeModal(USER_ACTION_TYPES.ADD_USER,null,groupData?.groups)}>
            <CommonModal open={isOpenWarningModal} onClose={closeWarningModal}>
                <WarningModal
                    onClose={closeWarningModal}
                    handlerProcessedClick={deleteUser}
                    description={t('modal.delete_description')}
                />
            </CommonModal>
            <CommonModal open={isOpenGroupWarningModal} onClose={closeGroupWarningModal}>
                <WarningModal
                    onClose={closeGroupWarningModal}
                    handlerProcessedClick={deleteUserGroup}
                    description={t('modal.delete_group_description')}
                />
            </CommonModal>
            <S.CheckboxWrapper>
                {checkboxGroupData?.length ? (
                    checkboxGroupData?.map((item) => (
                        <div key={item?.group_id}>
                            <S.CheckboxItem key={item?.group_id} active={item.checked || false}>
                                <CheckboxBold
                                    value={item?.group_id}
                                    label={item?.group_name}
                                    checked={item.checked}
                                    onChecked={handlerGroupChecked}
                                />
                                <span>
                                    { (item?.group_name !== "ADHOC") && <CopyIcon onClick={() => handlerChangeModal(USER_ACTION_TYPES.EDIT_GROUP, item)}/>}
                                </span>
                                {(item?.group_name !== "ADHOC") &&
                                <span onClick={() => handlerOpenGroupWarningModal(item)}>
                                    <RemoveIcon/>
                                </span>}
                            </S.CheckboxItem>
                            {
                                item?.contracts?.map(
                                    (subItem) => (
                                        <S.CheckboxSubItem key={subItem?.evcoId} active={subItem.checked || false} >
                                            <Checkbox
                                                value={subItem?.evcoId}
                                                label={subItem?.name}
                                                checked={subItem.checked}
                                                onChecked={handlerChecked}
                                                disabled={item.checked}
                                            />
                                            {(subItem?.name !== "ADHOC") && <span>
                                                <CopyIcon onClick={() => handlerChangeModal(USER_ACTION_TYPES.EDIT, subItem, groupData?.groups)}/>
                                            </span>}
                                            {(subItem?.name !== "ADHOC") && <span onClick={() => handlerOpenWarningModal(subItem)}>
                                                <RemoveIcon/>
                                            </span>}
                                        </S.CheckboxSubItem>
                                    )
                                )
                            }
                        </div>
                    ))
                ) : (
                    <p>{t('modal.no_contracts')}...</p>
                )}
                {/*{checkboxData?.length ? (
          checkboxData?.map((item) => (
            <S.CheckboxItem key={item?.evcoId} active={item.checked || false}>
              <Checkbox
                value={item?.evcoId}
                label={item?.name}
                checked={item.checked}
                onChecked={handlerChecked}
              />
              <span>
                <CopyIcon onClick={() => handlerChangeModal(USER_ACTION_TYPES.EDIT, item)} />
              </span>
              <span onClick={() => handlerOpenWarningModal(item)}>
                <RemoveIcon />
              </span>
            </S.CheckboxItem>
          ))
        ) : (
          <p>{t('modal.no_contracts')}...</p>
        )}*/}
            </S.CheckboxWrapper>
        </CommonModalWithHeader>
    );
};

export default AssignedUsers;
