import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, fromPromise } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { getToken } from '../utils/localStorage';
import { api } from '../services';
import { mergeArrayByField } from './utils';
import { GET_STATUS_FOR_CONNECTOR } from './chargingHistory';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL
});

const authMiddleware = setContext(async (_, { headers }) => {
  let token = getToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  let forward$;

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (error.extensions.code === 'INTERNAL_SERVER_ERROR') {
        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            api.authenticate
              .refreshToken()
              .then((token) => {
                const oldHeaders = operation.getContext().headers;

                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    Authorization: token ? `Bearer ${token}` : ''
                  }
                });

                resolvePendingRequests();
                return token;
              })
              .catch(() => {
                pendingRequests = [];
              })
              .finally(() => {
                isRefreshing = false;
              })
          );
        } else {
          // Will only emit once the Promise is resolved
          forward$ = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve());
            })
          );
        }
      }
    });
  }
  if (forward$) {
    return forward$.filter(Boolean).flatMap(() => forward(operation));
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Connector: {
        keyFields: ['evseId']
      }
    }
  }),
  link: ApolloLink.from([authMiddleware, errorLink, httpLink])
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
  mutation:{
    fetchPolicy: 'no-cache',
  }
}
const clientForUserTable = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
  link: ApolloLink.from([authMiddleware, errorLink, httpLink])
});

export default client;
export {clientForUserTable}
