import React from 'react';

import * as S from './styled';

export const CheckboxBold = ({ value, onChecked, label, checked }) => {
    const handlerChecked = (e) => {
        onChecked(e.target.value);
    };

    return (
        <S.FormControlWrapperBold
            label={label}
            control={<S.CustomCheckbox value={value} checked={checked} onChange={handlerChecked} />}
        />
    );
};


