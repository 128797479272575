import styled from 'styled-components';
import { textLarge, textMedium, textSmall } from '../../../styles/texts';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 24px 18px 20px;
  box-shadow: 0 2px 10px rgba(51, 51, 51, 0.2);
  background: ${({ theme }) => theme.color.background.secondary};
  border-radius: 15px;
`;

const ListImageBlock = styled.div`
  margin-right: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: 8px;
`;

const WaitingBlock = styled.div`
  margin-right: 6px;
`;

const ListImage = styled.img`
  max-width: 90px;
  max-height: 90px;
  object-fit: cover;
`;

const StatusBlock = styled.div`
  margin-right: 40px;
`;

const StatusBlockOccupied = styled.div`
  margin-right: 40px;
  filter: grayscale(100%);
`;

const StatusInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;

  svg {
    margin-right: 10px;
    width: 40px;
    height: 40px;

    * {
      ${({ theme, ready }) =>
        ready &&
        `
        fill: ${theme.color.background.green};
      `}
    }
  }

  ${({ theme, offline }) =>
    offline &&
    `
    margin-bottom: 0;
    
    svg > * {
      fill: ${theme.color.text.primary.dark};
    }
  `};
`;

const StatusInfoText = styled.p`
  ${textLarge};
  font-weight: 500;
`;

const ActionButton = styled.button`
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 120px;
  width: 100%;
  background: ${({ theme }) => theme.color.background.secondary};
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.15);
  border-radius: 8px;
  outline: none;
  border: none;
  ${textMedium};
  line-height: 100%;
  font-weight: 500;
  color: ${({ theme, charging }) =>
    charging ? theme.color.background.red : theme.color.background.green};
  cursor: pointer;
  transition: 0.3s all;

  svg {
    margin-right: 10px;
    width: 10px;
    height: 10px;
    fill: ${({ theme, charging }) =>
      charging ? theme.color.background.red : theme.color.background.green};
  }

  &:hover {
    color: ${({ theme }) => theme.color.background.secondary};
    background: ${({ theme, charging }) =>
      charging ? theme.color.background.red : theme.color.background.green};

    svg > path,
    svg > rect {
      fill: ${({ theme }) => theme.color.background.secondary};
    }
  }
`;

const TextBlock = styled.div``;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 42px;

  svg {
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const Title = styled.p`
  max-width: 280px;
  margin-right: 32px;
  ${textLarge};
  line-height: 22px;
  font-weight: 500;
`;

const SubTitle = styled.p`
  ${textSmall};
  line-height: 120%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.color.text.primary.darkGray};
`;

const UserBlockWrapper = styled.div`
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 40%;
  height: 94px;
  overflow: hidden;
  padding: 10px 14px;
  border: 1px solid ${({ theme }) => theme.color.background.outline};
  border-radius: 8px;
`

const UserBlock = styled.div`
  margin-left: auto;
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 40%;
  height: 94px;
  overflow: auto;
  padding: 10px 14px;
  border: 1px solid ${({ theme }) => theme.color.background.outline};
  border-radius: 8px;
`;

const UserItem = styled.div`
  padding: 8px 18px;
  margin-right: 14px;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: 8px;
  margin-bottom: 6px;
`;

const GroupItem = styled.div`
  padding: 8px 18px;
  margin-right: 14px;
  background: ${({ theme }) => theme.color.background.primary};
  border-radius: 8px;
  margin-bottom: 6px;
  font-weight: bold;
`;

const GroupItemBlock = styled.div`
  margin-bottom: 6px;

`;


const UserCopyIcon = styled.div`
  position: absolute;
  right: 14px;
  top: 14px;

  svg {
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
      opacity: 0.6;
    }
  }
`;

export {
  Wrapper,
  ListImageBlock,
  ListImage,
  StatusBlock,
  StatusInfo,
  StatusInfoText,
  ActionButton,
  TextBlock,
  TitleBlock,
  Title,
  SubTitle,
  UserBlock,
  WaitingBlock,
  UserItem,
  UserCopyIcon,
  StatusBlockOccupied,
  GroupItem,
  UserBlockWrapper,
  GroupItemBlock
};
