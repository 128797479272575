import { gql } from '@apollo/client';

export const GET_CONNECTOR_BY_ID = gql`
  query getConnectorByID($filter: ConnectorFilter!) {
    connectorQuick(filter: $filter) {
      id
      name
      evseId
      contracts {
        name
        evcoId
      }
    }
  }
`;


export const START_CHARGING = gql`
 mutation Mutation($contractConnector: ContractFilter!) {
  startCharging(Contract_Connector: $contractConnector)
 }
`

export const STOP_CHARGING = gql`
mutation Mutation($connectorTransaction: chargingControlFilter!) {
  stopCharging(Connector_Transaction: $connectorTransaction)
}
`
//"connectorTransaction": {
//    "evseId": "test1",
//        "transactionId": 99
//},

export const RESET_CHARGEPOINT = gql`
mutation Mutation($chargepointResetFilter: chargepointResetInput!) {
  resetChargepoint(chargepointResetFilter: $chargepointResetFilter)
}
`

//"chargepointResetFilter": {
//    "id":"test",
//        "type": "Hard"
//},