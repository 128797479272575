import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { CloseIcon } from '../../Icons';
import {useQuery} from "@apollo/client";
import {GET_CONTRACT_BY_ID} from "../../../apollo/contracts";

const AvailableUsers = ({ onClose, handlerChooseUser, users, evseId }) => {
  const { t } = useTranslation();

  const {data: contractData, loading: contractLoading} = useQuery(GET_CONTRACT_BY_ID,{
      variables:{
          contractFilter: {
              evseId: evseId
          },
      },
      fetchPolicy: "no-cache"
  })

  return (
    <S.Wrapper>
      <S.Header>
        <S.HeaderText>{t('modal.choose_user')}</S.HeaderText>
        <div onClick={onClose}>
          <CloseIcon />
        </div>
      </S.Header>

      <S.ExplainText>{t('modal.choose_user_explain')}</S.ExplainText>

      <S.Content>
        {contractData?.contracts?.length ? (
            contractData?.contracts?.map((user) => (
            <S.UserItem key={user.evcoId} onClick={() => handlerChooseUser(user)}>
              {user.name}
            </S.UserItem>
          ))
        ) : (
          <p>No assigned users...</p>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default AvailableUsers;
